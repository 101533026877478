import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.css';
 

function Navbar() {
  return (
    
    <div className='divi'>
              <p className='log'>
              <span className='span2'>M</span>

         <span className='span1'>édia</span> 
         <span className='span3'>-</span> 

         <span className='span2' >S</span> 
         <span className='span1'>col</span>
  

        </p>
 
    <nav className="navbar">
      <ul>
      
        <li>
          <NavLink exact to="/" activeClassName="active">Acceuil</NavLink>
        </li>
        <li>
          <NavLink to="./Formation" activeClassName="active">Formation</NavLink>
        </li>
        <li>
          <NavLink to="./Etudes" activeClassName="active">Etudes</NavLink>
        </li>
        <li>
          <NavLink to="./Epls" activeClassName="active">EPLS</NavLink>
        </li>
        <li>
          <NavLink to="./Fichiers" activeClassName="active">Fichiers</NavLink>
        </li>
        <li>
          <NavLink to="./Circulaires" activeClassName="active">Circulaires</NavLink>
        </li>

      </ul>
    </nav>
    </div>
  );
}

export default Navbar;
