import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import eplsss from './eplsss.PNG';
import { NavLink } from 'react-router-dom';



function Cardepls() {
  return (

    <div>
    <div class="card">
      <img src={eplsss} alt="Avatar" className='avatar'/>
      <div class="container">
        <h4><b>EPLS</b></h4> 
        <p>Architect & Engineer</p> 
        <div className='avat'> 
        
        <button className='btnn'>
        
        <NavLink to="./Epls" activeClassName="active" className='btnn'>{'>'}</NavLink>
        </button> 
        

        
        </div>
      </div>
    </div>
    
    </div>
    
  );
}

export default Cardepls;