import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import media from './media.png';
import formationss from './formationss.png'
import { NavLink } from 'react-router-dom';



function Cardformation() {
  return (

    <div>
    <div class="card">
      <img src={formationss} alt="Avatar" className='avatar'/>
      <div class="container">
        <h4><b>Formation </b></h4> 
        <p>Architect & Engineer</p> 
        <div className='avat'> 
        
        <button className='btnn'>
        
        <NavLink to="./Formation" activeClassName="active" className='btnn'>{'>'}</NavLink>
        </button> 
        

        
        </div>
      </div>
    </div>
    
    </div>
    
  );
}

export default Cardformation;